import Decimal from "decimal.js";

export type LyricWord = {
  time: number;
  text: string;
};

export type LyricLine = {
  time: number;
  words: LyricWord[];
  numWords: number;
};

export type LrcLyrics = {
  lines: LyricLine[];
  numLines: number;
};

// MARK: Not implemented yet.
// export type LrcLyricTimestamp = {
//   hour: number;
//   minutes: number;
//   seconds: number;
//   milliseconds: number;
// };

const tag2seconds = (tag: any) => {
  const mm = Number.parseInt(tag[1], 10);
  const ss = Number.parseFloat(tag[2].replace(":", "."));
  return mm * 60 + ss - 0.25;
};

export const parse = (lrcString: string): LrcLyrics => {
  const lines = lrcString.split(/\r\n|\n|\r/gu);

  const timeTag = /\[\s*(\d{1,3}):(\d{1,2}(?:[:.]\d{1,3})?)\s*]/s;
  // const timeTag1 = new RegExp(
  //   "[s*(d{1,3}):(d{1,2}(?:[:.]d{1,3}\\)\\?\\)s*]",
  //   "g",
  // );
  // const timeTag2 = new RegExp(
  //   "(\\[(\\d{1,3}):(d{1,2}(?:[:.]\\d{1,3})?)] )*",
  //   "g",
  // );
  // const newTimeTag = new RegExp(timeTag, "gy");
  // const newWordTag = new RegExp(
  //   "([\\p{sc=Hangul}\\p{sc=Han}\\p{sc=Hiragana}\\p{sc=Katakana}a-zA-Z0-9]+-(?:-[\\p{sc=Hangul}\\p{sc=Han}\\p{sc=Hiragana}\\p{sc=Katakana}a-zA-Z0-9)]+)*)",
  //   "u",
  // );
  // const wordTag =
  //   /\[(\d{1,3}):(\d{1,2}(?:[:.]\d{1,3})?)\s]*([a-zA-Z\u4e00-\u9fa5]+(?:-[a-zA-Z\u4e00-\u9fa5]+)*)/g;
  // const wordTag1 = new RegExp(
  //   `\\[(\\d{1,3}):(\\d{1,2}(?:[:.]\\d{1,3})?)\\s\\]*([\\p{sc=Hangul}|\\p{sc=Han}|\\p{sc=Hiragana}|\\p{sc=Katakana}|a-zA-Z0-9\u4e00-\u9fa5]+(?:-[\\p{sc=Hangul}|\\p{sc=Han}|\\p{sc=Hiragana}|\\p{sc=Katakana}|a-zA-Z\u4e00-\u9fa5]+)*)`,
  //   "ug",
  // );
  // const wordTag2 = new RegExp(
  //   "([\\p{sc=Hangul}|\\p{sc=Han}|\\p{sc=Hiragana}|\\p{sc=Katakana}|a-zA-Z0-9\u4e00-\u9fa5])+",
  //   "u",
  // );
  const wordTag3 = (raw: string): string[] => {
    let strs: string[] = [];

    for (let i = 0; i < raw.length; i++) {
      const char = raw.charAt(i);
      strs.push(char);
    }

    return strs;
  };

  const lyricsLines: LyricLine[] = [];

  let lyricsLinesBackup: LyricLine[] = [];
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line[0] !== "[") {
      continue;
    }
    const words: LyricWord[] = [];
    // timeTag.lastIndex = 0;
    const rTimeRough = timeTag.exec(line);
    const rTimeTag = rTimeRough.length > 0 ? rTimeRough[0] : null;
    // console.log(`i:${i}, rTimeTag:${rTimeTag}, rTimeTagRough.length:${rTimeTag.length}`);
    if (rTimeTag !== null) {
      const text = line.slice(rTimeTag.length);
      // console.log(`i:${i}, text:${text}`);
      // let wordMatch;
      // let wordMatch = wordTag2.exec(text);
      let wordMatch = wordTag3(text);
      // console.log("wordMatch:", wordMatch);
      for (let i = 1; i < wordMatch.length; i++) {
        const currentWord = wordMatch[i];
        // wordMatchBackup.push({
        //   time: 0,
        //   text: currentWord,
        // });
        words.push({
          time: tag2seconds(rTimeRough),
          text: currentWord,
        });
      }
      // while ((wordMatch = wordTag2.exec(line)) == null) {
      //   words.push({
      //     time: tag2seconds(rTimeRough),
      //     text: wordMatch,
      //   });
    }

    // MARK: Start Word Match
    // DEPRECATED
    // let wordMatch;
    // while ((wordMatch = newWordTag.exec(text)) != null) {
    //   words.push({
    //     time: tag2seconds(wordMatch),
    //     text: wordMatch[3],
    //   });
    // }
    // let wordMatch = newWordTag.exec(text);
    lyricsLinesBackup.push({
      time: tag2seconds(rTimeRough),
      words,
      numWords: words.length,
    });
  }

  for (let i = 0; i < lyricsLinesBackup.length; i++) {
    const lyricsLinesNow = lyricsLinesBackup[i];
    const lyricsLinesNext =
      i + 1 < lyricsLinesBackup.length
        ? lyricsLinesBackup[i + 1]
        : lyricsLinesBackup[i];
    const lrcNow: Decimal = new Decimal(lyricsLinesNow.time);
    const lrcNext: Decimal = new Decimal(lyricsLinesNext.time);
    const lrcGap: Decimal = lrcNext.sub(lrcNow);
    const nowLength: Decimal = new Decimal(lyricsLinesNow.numWords);
    const lrcTimestamp: Decimal = lrcGap.div(nowLength);

    const newLyricWords: LyricWord[] = [];

    for (let j = 0; j < nowLength.toNumber(); j++) {
      newLyricWords.push({
        time: Number(lrcNow.add(lrcTimestamp.mul(j)).toFixed(3)),
        text: lyricsLinesNow.words[j].text,
      });
    }

    lyricsLines.push({
      time: lyricsLinesNow.time,
      words: newLyricWords,
      numWords: newLyricWords.length,
    });
  }

  return {
    lines: lyricsLines,
    numLines: lyricsLines.length,
  };
};
